body {
  /* text-align: center; */
  background-color: #282c34;
  /* background-image: linear-gradient(to bottom right, #282c34, #454954); */
  color: #eee;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.home-cover {
  /* background-color: #282c34; */
  /* background-image: url(https://images.unsplash.com/photo-1502739391963-eda719c24cd4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80); */
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #eee;
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.dark-search-input {
  background-color: #282c34;
  border-color: #7a7e85;
}

.dark-search-button {
  color: #eee;
}

.dark-log-item {
  background-color: #1c1f24;
  border-color: #2f333c;
  border-radius: 5px;
}

.dark-pagination {
  background-color: #1c1f24;
  border-color: #2f333c;
}

.dark-log-active {
  background-color: #2a52a2;
  border-radius: 5px;
}
